//
// Header
// --------------------------------------------------

.header {
	.top-bar {		
		height: 30px;
		h1 {
			margin: 0;
			padding: 0;
			text-align: center;
			text-transform: uppercase;
			background: $brand-primary;
			color: $btn-primary-color;
			font-size: 18px;
			line-height: 30px;
		}
		p.user-login {
			margin: 0;
			padding: 0;
			height: 30px;
			line-height: 30px;
			text-align: right;
			color: $brand-primary;
			white-space: nowrap;
		}	
	}
	.bar {
		height: 100px;
		position: relative;
		.logo {
			height: 100px;
			background: url(../images/GasserMedia.png) no-repeat center center;
			span {
				display: none;
			}
		}
		h1 {
			margin: 0;
			padding: 0;
			font-size: 36px;
			font-weight: normal;
			text-transform: uppercase;
			position: relative;
			top: 75%;
  			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			transform: translateY(-50%);
		}
		.bar-left, .bar-center, .bar-right {
			height: 100px;
		}
	}
}
