//
// Forms
// --------------------------------------------------

.form-control {
	background-color: $input-bg-color;
	@include box-shadow( none );
	
	&:focus {
		@include box-shadow( inset 0 1px 1px rgba( 0, 0, 0, 0.075 ) ); 
	}
}

// User params images
.form-group
{
	.radio-container
	{
		label
		{
			float: none !important;
			width: auto !important;

			img
			{
				max-width: 90px;
				pointer-events: none;
			}
		}
	}
}