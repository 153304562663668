//
// Proof
// --------------------------------------------------

.warning {
	color: $text-warning;
}

.error {
	color: $text-danger;
}

a.prefix-icon {
    background-position: left center;
    background-repeat: no-repeat;
    display: inline-block;
    min-height: 18px;
    padding-left: 22px;
    color: $text-color;
}
