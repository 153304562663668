// http://bootsnipp.com/snippets/featured/material-design-tab-style

.nav-container
{
	@extend .main;

	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
}

.nav-container + .main
{
	border-top: none;
}

.nav-tabs
{
	border-bottom: 1px solid $table-border-color;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover
{
	border-width: 0;
}

.nav-tabs > li > a
{
	padding-bottom: 15px;
	border: none;
	color: $text-default;

	&:hover,
	&:focus {
		background-color: transparent;
	}
}

.nav-tabs > li.active > a,
.nav-tabs > li > a:hover
{
	border: none;
	color: $brand-primary !important;
	background: transparent;
	font-weight: bold;
}

.nav-tabs > li > a::after
{
	content: "";
	background: none;
	height: 3px;
	position: absolute;
	width: 100%;
	left: 0px;
	bottom: 0px;
}

.nav-tabs > li.active > a::after,
.nav-tabs > li:hover > a::after
{
	background: $brand-primary;
}
