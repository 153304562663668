@charset 'UTF-8';

// Override the Bootstrap variables and define your own here.

@import "colors";
@import "bootstrap/variables";

$fa-font-path: '../fonts/font-awesome';

$grid-columns: 16;
$grid-gutter-width: 30px;

//
// Override any variables
// --------------------------------------------------

$font-family-sans-serif:  "TStarPro", Arial, Helvetica, FreeSans, sans-serif;
$font-family-base:        $font-family-sans-serif;
$headings-font-family:    $font-family-base;

$input-color: $text-default;
$input-color-placeholder: $text-default-placeholder;

$btn-border-radius-base: 0;
$btn-border-radius-large: 0;
$btn-border-radius-small: 0;
 
$input-border: $brand-default;
$input-border-focus: darken( $brand-default, 20% );
$input-border-radius: 0;
$input-border-radius-large: 0;
$input-border-radius-small: 0;

$text-warning: darken( $brand-warning, 10% );
$text-danger: darken( $brand-danger, 10% );

$label-default-bg:            $brand-default;
