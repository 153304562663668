//
// Catalogue
// --------------------------------------------------

.catalogue-enable-all
{
	padding: 0 0 4px;
	margin: 20px 20px -35px;
	border-bottom: 1px solid #ddd;
}

ul.chapter-list {
	margin: 40px 0 20px 0;
	padding: 0;
	list-style-type: none;

	li {
		display: block;
		margin: 5px 0;
		color: $text-disabled;
		font-weight: bold;
		font-size: 16px;
		position: relative;

		&:hover, &:active {
			background: $hover-background;
			a.chapter {
				color: $brand-primary;
			}
		}

		span.chapter.in, a.chapter {
			display: inline-block;
			min-width: 400px;
			padding: 5px 10px;	
		}

		a.chapter {
			color: $text-color;
		}

		a.cbx {
			display: inline-block;
			text-align: right;
			padding: 6px;
			span.loading {
				display: inline-block;
				background: url(../images/ajax_loader.gif) no-repeat center center;
				width: 16px;
				height: 16px;
				&.glyphicon-unchecked:before, &.glyphicon-check:before {
					content: "";
					display: table;
				}				
			}			
		}
		
		.closed & a.cbx {
			color: lighten($brand-primary, 40%);
		}
	}	
}

.catalogue-action {
	background: #fff;
	border: 1px solid $brand-default;
	border-radius: 0 0 0 4px;
	font-size: 0;

	a {
		display: inline-block;
		text-align: center;
		font-size: 16px;
		padding: 3px 0 2px;
		width: 28px;
		
		&:hover,
		&:active {
			color: #fff;
			background: $brand-primary;
		}
	}
}

ul.container-details
{
	margin: 0;
	padding: 0;
	list-style-type: none;

	li {
		margin-bottom: 16px;
		
		&.current {
			border-left: 2px solid $brand-primary;
			padding-left: 9px;
		}
	
		&:hover {
			border-color: $brand-primary;
		}
	}

	.title a {
		display: block;
		color: $text-color;
		font-size: 14px;
		padding: 0 3px;
	}

	.title a:hover {
	}
	
	.current .title {
		display: block;
		font-weight: bold;
		color: $brand-primary;
		border-color: $brand-primary;
	}

	.catalogue-info {
		padding: 0 3px;
		font-size: .8em;
	}

	ul {
		margin: 0 0 0 9px;
		padding: 0;
		list-style-type: none;
		
		li
		{
			padding-left: 9px;
			border-left: 2px solid transparent;
			
			&.current {
				border-color: $brand-primary;
			}
		}
	}
}
