//
// Login
// --------------------------------------------------

.data-solution {
	width: 100%;
	height: 150px;
	background: url(../images/DataSolution.png) no-repeat center center;
	margin-top: 20px;
	margin-bottom: 20px;	
}
