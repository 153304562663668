@charset 'UTF-8';

// List your font definitions here.

// this font is much too light, so use medium as regular
@font-face {
	font-family: "TStarPro";
	src: url('../fonts/TStarProMedium/tstarpro-medium-webfont.eot');
	src: url('../fonts/TStarProMedium/tstarpro-medium-webfont.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/TStarProMedium/tstarpro-medium-webfont.woff') format('woff'),
	     url('../fonts/TStarProMedium/tstarpro-medium-webfont.ttf') format('truetype'),
	     url('../fonts/TStarProMedium/tstarpro-medium-webfont.svg#TStarProMedium') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TStarPro';
	src: url('../fonts/TStarProBold/tstarpro-bold-webfont.eot');
	src: url('../fonts/TStarProBold/tstarpro-bold-webfont.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/TStarProBold/tstarpro-bold-webfont.woff') format('woff'),
	     url('../fonts/TStarProBold/tstarpro-bold-webfont.ttf') format('truetype'),
	     url('../fonts/TStarProBold/tstarpro-bold-webfont.svg#TStarProBold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'TStarPro';
	src: url('../fonts/TStarProMediumItalic/tstarpro-mediumitalic-webfont.eot');
	src: url('../fonts/TStarProMediumItalic/tstarpro-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/TStarProMediumItalic/tstarpro-mediumitalic-webfont.woff') format('woff'),
	     url('../fonts/TStarProMediumItalic/tstarpro-mediumitalic-webfont.ttf') format('truetype'),
	     url('../fonts/TStarProMediumItalic/tstarpro-mediumitalic-webfont.svg#TStarProMediumItalic') format('svg');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'TStarPro';
	src: url('../fonts/TStarProBoldItalic/tstarpro-bolditalic-webfont.eot');
	src: url('../fonts/TStarProBoldItalic/tstarpro-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/TStarProBoldItalic/tstarpro-bolditalic-webfont.woff') format('woff'),
	     url('../fonts/TStarProBoldItalic/tstarpro-bolditalic-webfont.ttf') format('truetype'),
	     url('../fonts/TStarProBoldItalic/tstarpro-bolditalic-webfont.svg#TStarProBoldItalic') format('svg');
	font-weight: bold;
	font-style: italic;
}
