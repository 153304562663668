//
// Global layout
// --------------------------------------------------

body {
	position: relative; // for bootstrap scrollspy
}

html, body {
	height: 100%;
}

#global {
	min-height: 100%;
	height: auto;
	margin: 0 auto;
}

//
// Global styles
// --------------------------------------------------

a:hover,
a:focus {
	text-decoration: none;	
}

.clear
{
	clear: both;
}

//
// Content
// --------------------------------------------------

.content {
	.main {
		border-top: 1px solid $ui-border;
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

//
// Spinner
// --------------------------------------------------

.spinner-loading
{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 60px;
	height: 20px;
	margin: 0 auto;
}
.spinner-loading .spinner-container
{
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}
.spinner-loading .spinner-container div
{
	position: absolute;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #fff;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.spinner-loading .spinner-container div:nth-child(1)
{
	margin-left: -28px;
	animation: spinner1 0.6s infinite;
}
.spinner-loading .spinner-container div:nth-child(2)
{
	margin-left: -28px;
	animation: spinner2 0.6s infinite;
}
.spinner-loading .spinner-container div:nth-child(3)
{
	margin-left: 0px;
	animation: spinner2 0.6s infinite;
}
.spinner-loading .spinner-container div:nth-child(4)
{
	margin-left: 28px;
	animation: spinner3 0.6s infinite;
}
@keyframes spinner1
{
	0%
	{
		transform: scale(0);
	}
	100%
	{
		transform: scale(1);
	}
}
@keyframes spinner3
{
	0%
	{
		transform: scale(1);
	}
	100%
	{
		transform: scale(0);
	}
}
@keyframes spinner2
{
	0%
	{
		transform: translate(0, 0);
	}
	100%
	{
		transform: translate(14px, 0);
	}
}
