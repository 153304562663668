//
// Colors
// --------------------------------------------------

$brand-primary: #5a2981;
$brand-default: #9b8a78;

$ui-border: #000000;
$ui-background: #f8f8f8;
$hover-background: #eeeeee;

$input-bg-color: $ui-background;

// Text

$text-color: #000000;
$text-disabled: lighten( $text-color, 60% );
$text-primary: $brand-primary;
$text-default: darken( $brand-default, 10% );
$text-default-placeholder: $brand-default;

// flashy button
$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-lighten: 15%;
$btn-danger: #d9534f;
$btn-warning: #f0ad4e;
$btn-sky: #5bc0de;
$btn-valid: #5cb85c;
$btn-ocean: #337ab7;

// neutral button
$btn-default-color: #fff;
$btn-default-bg: $brand-default;
$btn-default-lighten: 15%;
