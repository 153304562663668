//
// Grid
// --------------------------------------------------

.container {
	@include container-fixed();
}

.row {
	@include make-row();	
}

@include make-grid-columns();

@include float-grid-columns(md);
@include make-grid($grid-columns);

.container {
	max-width: none !important;
	width: 1170px;
}
