//
// Catalogue list
// --------------------------------------------------

.group-toc {
	.group-toc-scroller {
		overflow-y: scroll;

		&.affix {
			top: 0;
			bottom: 0;
		}
	
		ul {
			margin: 10px 0 0 0;
			padding: 0;
	
			list-style-type: none;
	
			> li > a
			{
				padding: 0 0 0 4px;
				margin-left: 4px;
				color: $text-color;
				border-left: 2px solid transparent;
				border-radius: 0;
		
				&:hover {
					background: none;
					color: $brand-primary;
				}
			}

			> li.active a {
				color: $brand-primary;
				background: none;
				border-color: $brand-primary;
		
				&:hover {
					background: none;
					color: $brand-primary;
				}
			}
		}
	}
}

ul.category-list {
	margin: 40px 0 20px 0;
	padding: 0;
	list-style-type: none;

	li {
		display: block;
		margin: 20px 0;

		&.archived
		{
			display: none;
		}

		&.title {
			color: $brand-primary;
			font-size: 1.4em;
			font-weight: bold;
			border-bottom: 1px solid $brand-primary;
			margin-top: 60px;
			margin-bottom: 30px;
		}

		.element {
			position: relative;
			
			.main-link {

				a {
					display: inline-block;
					width: 100%;

					.title {
						display: block;
						color: $text-color;
						font-size: 16px;
						padding: 3px;
					}

					.catalogue-info {
						color: $text-default;
						font-size: 12px;
						display: inline-block;
						padding: 0 3px;
						width: 100%;
					}
				}
			}

			.action-links {
				display: none;
				position: absolute;
				top: 0;
				right: 0;
				text-align: right;
				background: #fff;
				border: 1px solid $brand-default;
				border-radius: 0 0 0 4px;
				font-size: 0;

				a {
					display: inline-block;
					text-align: center;
					font-size: 16px;
					padding: 3px 0 2px;
					width: 28px;
					
					&:hover,
					&:active {
						color: #fff;
						background: $brand-primary;
					}
				}
			}

			&:hover,
			&:active {
				background: $hover-background;

				.main-link {
					.title {
						color: $brand-primary;
					}
				}

				.action-links {
					display: block;
				}
			}
		}
	}

	ul.variant-list
	{
		li:first-child {
			margin-top: 4px;
			padding-top: 10px;
		}
		
		margin-top: 0;
		margin-left: 4px;
		padding-left: 15px;
		list-style-type: none;
		border-left: 2px solid $brand-default;
	}
}

div.parameters-list
{
	h2 {
		font-size: 1.2em;
		a.parameters-edit {
			font-size: .9em;
		}
	}

	dl {
		dt {
			color: $text-disabled;
		}

		dd {
			font-size: .9em;
			padding: 2px 0 2px 5px;
		}
	}
}

.mod-archives-display
{
	padding: 5px 0;
	color: $text-default;
	
	input
	{
		display: none;
	}
}
