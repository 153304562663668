.breadcrumb {
}

.top-bar .breadcrumb {
	margin: 4px 0 0 0;
	padding: 0;
	height: 25px;
	line-height: 25px;
	background: none;
}
