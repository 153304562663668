//
// Buttons
// --------------------------------------------------

// mixin to make a button that lightens on hover
@mixin button-variant-ds( $color, $background, $border, $lighten )
{
	$accent-background: if( $lighten < 0,
		darken( $background, -$lighten ),
		lighten( $background, $lighten )
	);
	
	@include button-variant( $color, $background, $border );
	&:focus,
	&.focus,
	&:hover
	{
		background-color: $accent-background;
	}

	&:active,
	&.active,
	.open > &.dropdown-toggle {
		background-color: $accent-background;

		&:hover,
		&:focus,
		&.focus {
			background-color: $accent-background;
		}
	}
}

// a button group from which I have removed those fugly grey top and bottom borders
.button {
	margin-bottom: 8px;
}

.btn {
	border-width: 0;
	padding: 5px 12px;
}

.btn-icon {
	padding-right: 6px;

	i {
		display: inline-block;
		@include ie7-inline-block();
		margin: 0 0 0 20px;

		font-size: 1em;
		width: 1.25em;
		text-align: center;
	}
}

.btn-icon.btn-block i {
	margin-left: 6px;
}

.btn.btn-block.btn-icon {
	text-align: right;

	span.text {
		display: block;
		float: left;
	}
}

.btn:focus,
button:focus {
	// I’m not sure about that yet
	// outline: none;
}

// lighten those buttons on hover

.btn-primary {
	@include button-variant-ds(
		$btn-primary-color,
		$btn-primary-bg,
		$btn-primary-border,
		$btn-primary-lighten
	);
}

.btn-default {
	@include button-variant-ds(
		$btn-default-color,
		$btn-default-bg,
		$btn-default-border,
		$btn-default-lighten
	);
}

.btn-close {
	display: inline-block;

	i {
		margin-left: 10px;
		display: inline-block;

		color: $btn-primary-color;
		background: $brand-primary;

		padding: 3px;
	}

	&:hover i, &:active i {
		background: lighten( $brand-primary, 20% );
	}
}

.btn-sorting,
.btn-groups
{
	margin-right: 10px;
}

.btn-groups button .text
{
	display: block;
	margin-right: 0.5em;
	max-width: 160px;
	overflow: hidden;
	text-overflow: ellipsis;
	float: left;
}

.dropdown-menu > li > a.active {
	font-weight: bold;
}
